import React from "react";
import Loader from "./app/loader/loader";
// preloader
export default class Pre_loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { comp: false, loaded: false };
        this.renderApp = this.renderApp.bind(this);
        this.onLoaderAnimationEnd = this.onLoaderAnimationEnd.bind(this);
        this.loaded = this.loaded.bind(this);
    }
    componentDidMount() {
        import("./App.js").then((data) => {
            // when the app is loaded
            // console.log("app loaded");
            this.loaded()
            this.loaded_app=data;
        }).catch(err => {
            console.error(err)
        })
    }
    loaded(data) {
        // this function fires on component loading complete
        this.setState({ loaded: true })
    }
    onLoaderAnimationEnd() {
        // this function is execute when loaded animation is complete
        this.renderApp(this.loaded_app)
    }
    renderApp(data) {
        // console.log("rendering app")
        this.setState({ comp: data });
    }
    render() {
        if (this.state.comp) {
            // if the component is loaded
            const Comp = this.state.comp;
            return <Comp.default />
        } else {
            return (
                <Loader onAnimationEnd={() => { this.onLoaderAnimationEnd() }} loaded={this.state.loaded} />
            )
        }
    }
}